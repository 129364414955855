
























import Vue from 'vue'

export default Vue.extend({
  props: {
    count: {
      default: () => 0,
      type: Number,
    },
    label: {
      default: ``,
      type: String,
    },
    path: {
      default: ``,
      type: String,
    },
  },
})
