



































































import Vue from 'vue'
import StatCard from '@/components/Dashboard/StatCard.vue'
import {
  chapterStore,
  grammarStore,
  lessonStore,
  placeStore,
  wordStore,
  snackbarStore,
} from '@/store'
import {
  Chapter,
} from '@/types/chapter'
import {
  Grammar,
} from '@/types/grammar'
import {
  Lesson,
} from '@/types/lesson'
import {
  Place,
} from '@/types/place'
import {
  Word,
} from '@/types/word'
import {
  Id,
} from '@/types/base'

export default Vue.extend({
  components: {
    StatCard,
  },
  computed: {
    chapters (): (Chapter & Id)[] {
      return chapterStore.data
    },
    grammars (): (Grammar & Id)[] {
      return grammarStore.data
    },
    lessons (): (Lesson & Id)[] {
      return lessonStore.data
    },
    places (): (Place & Id)[] {
      return placeStore.data
    },
    words (): (Word & Id)[] {
      return wordStore.data
    },
  },
  async created () {
    try {
      chapterStore.getAll()
      grammarStore.getAll()
      lessonStore.getAll()
      placeStore.getAll()
      wordStore.getAll()
    } catch (err) {
      snackbarStore.addSiteError({
        err,
        snackMessage: `Unable to load data`,
      })
    }
  },
})
